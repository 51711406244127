'use client';

import { appBranding } from '@/data/application-branding';
import { Page404 } from '@into-discovery/browsewebshared';
import { useLocale } from 'next-intl';

export default function NotFoundProvider() {
  const locale = useLocale();
  const appSettings = {
    branding: appBranding,
  };

  return (
    <Page404 locale={locale} appSettings={appSettings} homepageLinkText="INTO Study Homepage" />
  );
}
